<template lang="pug">
.login_container
  Alert.mr-5
  validation-observer.w-50(v-slot='{handleSubmit, errors}')
    b-form(@submit.prevent='handleSubmit(loginUser)')
      b-form-group.text-center
        h4 Iniciar sesión
      b-form-group
        validation-provider(rules='required|alpha_num', name='usuario', v-slot='{valid, dirty}')
          b-form-input.text-center(v-model='user', placeholder='Usuario', :class='{ invalid: dirty && !valid }')
      b-form-group
        validation-provider(rules='required|alpha_num', name='contraseña', v-slot='{valid, dirty}')
          b-form-input.text-center(v-model='password', type='password', placeholder='Contraseña', :class='{ invalid: dirty && !valid }')
      b-form-group
        b-button.w-100(type='submit', variant='primary') Entrar
      b-form-group.text-center
        p.text-danger(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)', :key='error') {{ error }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Alert from '../components/Alert'

export default {
  name: 'Login',
  components: {
    Alert,
  },
  data() {
    return {
      user: null,
      password: null,
    }
  },
  computed: {
    ...mapState(['admin', 'isLogin']),
  },
  methods: {
    ...mapActions(['login']),
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
    async loginUser() {
      try {
        if (await this.login({ user: this.user, password: this.password })) {
          this.$router.push({ name: 'Admin' })
        } else {
          this.$alert.$emit('alert', {
            type: 'warning',
            message: 'El usuario y password son invalidos',
          })
        }
      } catch (error) {
        this.$alert.$emit('alert', {
          type: 'danger',
          message: 'Error en el login ' + error,
        })
      }
    },
  },
  mounted() {
    if (!!this.admin && this.isLogin) {
      this.$router.push({ name: 'Admin' })
    }
  },
}
</script>

<style lang="scss" scoped>
.login_container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid {
  border: 2px solid red;
}
</style>